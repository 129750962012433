import { useRef, useEffect, useState } from "react";
import { Link } from 'react-scroll';
import { FaBars, FaTimes } from "react-icons/fa";
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

const NavBar = () => {
	const navRef = useRef();
	const [scrolled, setScrolled] = useState(false);

	useEffect(() => {
		const onScroll = () => {
			if (window.scrollY > 160) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		}

		window.addEventListener("scroll", onScroll);

		return () => window.removeEventListener("scroll", onScroll);
	}, []);
	const showNavbar = () => {
		navRef.current.classList.toggle("responsive_nav");
	};

	return (
		<header className={scrolled ? "scrolled" : ""}>
			<div className={`navbrand ${scrolled ? "scrolled" : ""}`} />
			<nav ref={navRef}>
				<div className="nav-list">
					<a href="/#" ><Link to="acceuil" className={scrolled ? 'scrolled' : ''} spy={true} smooth={true} onClick={showNavbar}>Acceuil</Link></a>
					<a href="/#" > <Link className={scrolled ? 'scrolled' : ''} to="services" spy={true} smooth={true} onClick={showNavbar}>Services</Link></a>
					<a href="/#" ><Link className={scrolled ? 'scrolled' : ''} to="contact" spy={true} smooth={true} onClick={showNavbar}>Contact</Link></a>
				</div>
				<div className="social-icon">
					<a href="https://www.linkedin.com/company/technologieworld" target="_blank" onClick={showNavbar}><img src={navIcon1} alt="" /></a>
					<a href="https://www.facebook.com/tech.w0rld.dz" target="_blank" onClick={showNavbar}><img src={navIcon2} alt="" /></a>
					<a href="https://www.instagram.com/tech_world.dz/" target="_blank" onClick={showNavbar}><img src={navIcon3} alt="" /></a>
				</div>
				<button
					className={`nav-btn  ${scrolled ? "nav-close-btn-scrolled" : "nav-close-btn"}`}
					onClick={showNavbar}>
					<FaTimes />
				</button>
			</nav>
			<button className={`nav-btn ${scrolled ? "scrolled" : ""}`} onClick={showNavbar}>
				<FaBars />
			</button>
		</header>
	);
}

export default NavBar;
