import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"

export const QuiSommesNous = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="quisommesnous" id="quisommesnous">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="quisommesnous-bx wow zoomIn">
                        <h2>Qui sommes-nous ?</h2>
                        <p> Sérieuse, talentueuse et dynamique équipe de jeunes algériens. TechWorld est fière de vous accompagner dans vos projets et nous éxecutons votre stratégie de digitalisation et de transformation numérique. <br/><span className="text-warning fw-bolder" style={{fontSize:"27px"}}>TechWorld </span> votre partenaire fidele </p>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="" />
    </section>
  )
}
