import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import  Services  from "./Services";
import projImg1 from "../../assets/img/project-img1.png";
import projImg2 from "../../assets/img/project-img2.png";
import trainig from "../../assets/img/training.svg";
import SAP from "../../assets/img/sap.svg";
import odoo from "../../assets/img/odoo.svg";
import code from "../../assets/img/code.svg";
import consulting from "../../assets/img/consulting.svg";
import support from "../../assets/img/support.svg";
import colorSharp2 from "../../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

const index = () => {

  const services = [
    {
      title: "SAP ERP",
      description: "Intégration & Maintenance",
      imgUrl: SAP,
    },
    {
      title: "Odoo ERP",
      description: "Intégration & Maintenance",
      imgUrl: odoo,
    },
    {
      title: "Dévelopement de Software",
      description: "Design & Development",
      imgUrl: code,
    },
    {
      title: "Consulting et Support",
      description: "",
      imgUrl: consulting,
    },
    {
      title: "Coaching & Formation",
      description: "",
      imgUrl: support,
    },
    {
      title: "Fomation des particuliers",
      description: "",
      imgUrl: trainig,
    },
  ];

  return (
    <section  className="services" id="services">
      <Container>
        <Row>
          <Col size={5}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h1>Nos Services  </h1>
                <Tab.Container id="services-tabs" defaultActiveKey="first">
                  <Row >
                        {
                          services.map((services, index) => {
                            return (
                              <Services 
                                key={index}
                                {...services}
                                />
                            )
                          })
                        }
                      </Row>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
export default index;
