import React from 'react';import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import NavBar  from "./components/NavBar";
import { Acceuil } from "./components/Acceuil";
import { QuiSommesNous } from "./components/QuiSommesNous";
import  Services   from "./components/Services";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";

function App() {
  return (
    <div className="App">
			<NavBar/>
      <Acceuil />
      <QuiSommesNous />
      <Services />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
