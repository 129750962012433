import { Row, Col } from "react-bootstrap";
import logoFooter from "../assets/img/logo_footer.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import FaxRoundedIcon from '@mui/icons-material/FaxRounded';

/**<MailchimpForm /> between first row and col */
export const Footer = () => {
  return (
    <footer className="bg-dark text-white pt-5 pb-4" id="footer">
      <div className="container text-center text-md-left">
        <Row className=" text-left text-md-left" style={{ "justify-content": "center", "align-items": "flex-start" }}>

          <Col className=" mx-auto mt-3" md={3} lg={6} xl={3} >
            <h5 className="text-uppercase mb-4 font-weight-bold text-warning">Services</h5>
            <p  style={{ "cursor": "pointer", "color": "white" }}>SAP</p>
            <p  style={{ "cursor": "pointer", "color": "white"}}>Odoo</p>
            <p style={{ "cursor": "pointer", "color": "white" }}>Developpement Software</p>
            <p style={{ "cursor": "pointer", "color": "white" }}>Consulting & Support</p>
          </Col>
          <Col className=" mx-auto mt-3" md={3} lg={6} xl={3} >
            <h5 className="text-uppercase mb-4 font-weight-bold text-warning">Contact</h5>
            <p className="text ">
              <HomeIcon /> 21 Cité Djenane El Afia Birkhadem
            </p>
            <p>
              <EmailIcon /> contact@technologie-world.com
            </p>
            <p id="phone_number">
              <PhoneEnabledIcon /> +213 549 62 66 56
            </p>
            <p>
              <FaxRoundedIcon /> 023 56 44 62
            </p>
          </Col>
          <Col className=" mx-auto mt-3" md={3} lg={6} xl={3} style={{ "justify-content": "flex-start", "align-items": "flex-start" }} >
            <img src={logoFooter} style={{ "background-size": "cover", "width": "90%" }}></img>
          </Col>
        </Row>
        <hr className="mb-4" />
        <Row className=" align-items-center">
          <Col className="" md={7} lg={8}>
            <p className="text-align text-center">&copy;{new Date().getFullYear()}. Tous les droits sont réservés par:

              <a href="#" style={{ "textDecoration": "none" }}>
                <strong className="text-warning"> TechWorld</strong>
              </a>
            </p>
          </Col>
          <Col className="s" md={5} lg={4} >
            <div className="text-center text-md-right" >
              <ul className="list-unstyled list-inline">
                <li className="list-inline-item">
                  <a href="https://www.linkedin.com/company/technologieworld" target="_blank" className="btn-floating btn-lg text-white"><LinkedInIcon /></a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/tech.w0rld.dz" target="_blank" className="btn-floating btn-lg text-white"><FacebookIcon /></a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.instagram.com/tech_world.dz/" target="_blank" className="btn-floating btn-lg text-white"><InstagramIcon /></a>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  )
}
